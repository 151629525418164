import {
  Button, Tooltip, Input, Modal, Checkbox, Tag, Switch, Collapse,
} from 'antd'
import { parse, stringify } from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StarRatings from 'react-star-ratings';
import { CaretRightOutlined } from '@ant-design/icons';
import Loader from '../../../common/components/Loader'
import InputWithCopy from '../CopyToClipboard';
import {
  deletePiFromNetworkAction,
  getNetworkDetailsAction,
  // getPiAutoSuggestAction,
  getPiAutoSuggestResetAction,
  putPiToNetworkAction,
  // getPiAutoSuggestMoreAction,
  getNetworkDetailsMoreAction,
  deletePiFromNetworkResetAction,
  putPiToNetworkResetAction,
} from './logic'
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import { isViewOnlyAccess, notify } from '../../../utils';
import SearchResultFilters from '../SearchResults/searchResultFilters';
import { getDatasetListDataAction, getMoreDatasetListDataAction } from '../SearchResults/logic';
import { getAutocompleteValuesCancelAction, getAutocompleteValuesResetAction } from '../SearchBar/logic';

const NetworkCenterList = ({
  appliedFilters, networkType, centerList, innoplexusId,
}) => {
  const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  const networkData = useSelector((store) => store.getNetworkDetails)
  const deleteCenterFromNetwork = useSelector((store) => store.deletePiFromNetwork)
  // const piAutoSuggest = useSelector((store) => store.getPiAutoSuggest)
  const putCenterToNetwork = useSelector((store) => store.putPiToNetwork)
  // const [networkMetaDetails, setNetworkMetaDetails] = useState({})
  // const [localLoading, setLocalLoading] = useState(true)
  // const [piList, setPiList] = useState()
  const [centerValueToSearch, setCenterValueToSearch] = useState('')
  const [addCenterToNetworkModal, setAddNewCenterToNetworkModal] = useState(false)
  const [fromForCenter, setFromForCenter] = useState(1);
  const [fromForCenterInNetworkList, setFromForCenterInNetworkList] = useState(1)
  const [deleteModal, setDeleteModal] = useState(false)
  const [centerListToSend, setCenterListToSend] = useState([])
  // const [piNameToSearch, setPiNameToSearch] = useState('')
  const [centerIdToDelete, setCenterIdToDelete] = useState('')

  const dispatch = useDispatch()
  // const modifyNetworkRespose = useSelector((store) => store.modifyNetwork);

  const { Search } = Input
  const { Panel } = Collapse

  const type = 'centers';

  const [switchChange, setSwitchChange] = useState(false);
  const [appliedFiltersForCenter, setAppliedFiltersForCenter] = useState({
    alliance: undefined,
    quality_rating: undefined,
    tier: undefined,
  });

  const [query, setQuery] = useState('');
  const [searchText, setSearchText] = useState('');
  const autoCompleteData = useSelector((state) => state.autoCompleteData);
  const datasetListData = useSelector((state) => state.datasetListData);
  const [CenterList, setCenterList] = useState([]);

  useEffect(() => {
    if (datasetListData.flag) {
      setCenterList(datasetListData.data.data);
    }
  }, [JSON.stringify(datasetListData)]);

  useEffect(() => {
    setCenterList([])
  }, [])

  // useEffect(() => {
  //   const network_id = parse(location.search).id
  //   const from = 1
  //   const size = 10
  //   const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })

  //   dispatch(getNetworkDetailsAction({
  //     type, network_id, from, size, filters,
  //   }))
  //   setFromForPiInNetworkList(fromForPiInNetworkList + 1)
  // }, [])

  useEffect(() => {
    // const network_id = parse(location.search).id
    // const from = 1
    // const size = 10
    // const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })

    // dispatch(getNetworkDetailsAction({
    //   type, network_id, from, size, filters,
    // }))
    setFromForCenterInNetworkList(1)
  }, [appliedFilters])

  // useEffect(() => {
  //   if (modifyNetworkRespose.flag) {
  //     const network_id = parse(location.search).id
  //     const from = 1
  //     const size = 10
  //     const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
  //     setFromForPiInNetworkList(1)
  //     dispatch(getNetworkDetailsAction({
  //       type, network_id, from, size, filters,
  //     }))
  //   }
  // }, [JSON.stringify(modifyNetworkRespose)]);

  useEffect(() => {
    if (putCenterToNetwork.flag) {
      const network_id = parse(location.search).id
      const from = 1
      const size = 10
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
      setFromForCenterInNetworkList(1)
      setCenterList([])
      setSearchText('')
      dispatch(getNetworkDetailsAction({
        type, network_id, from, size, filters,
      }))
      dispatch(putPiToNetworkResetAction({}))
      setSwitchChange(false)
    }
  }, [JSON.stringify(putCenterToNetwork)])

  // useEffect(() => {
  //   if (networkData.flag) {
  //     setNetworkMetaDetails(networkData.data.data.network_details)
  //     setPiList(networkData.data.data.pi_summary)
  //     setLocalLoading(false)
  //   }
  // }, [JSON.stringify(networkData)])

  useEffect(() => {
    if (deleteCenterFromNetwork.flag) {
      const network_id = parse(location.search).id
      const from = 1
      const size = 10
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
      setCenterList([])
      setSearchText('')
      setFromForCenterInNetworkList(1)
      dispatch(getNetworkDetailsAction({
        type, network_id, from, size, filters,
      }))
      dispatch(deletePiFromNetworkResetAction({}))
    }
  }, [JSON.stringify(deleteCenterFromNetwork)])

  // const onInvestigatorChangeInput = (e) => {
  //   if (e.trim() && e.length > 2) {
  //     setPiNameToSearch(e)
  //     dispatch(
  //       getPiAutoSuggestAction({
  //         query: e.trim(),
  //         from: 1,
  //         dataset: 'centers',
  //         size: 10,
  //         filters: JSON.stringify({ parent_flag: false }),
  //       }),
  //     );
  //     setFromForCenter(1)
  //   }
  // };

  // const loadMoreRowsPiList = () => {
  //   dispatch(
  //     getPiAutoSuggestMoreAction({
  //       query: piNameToSearch,
  //       from: fromForCenter + 1,
  //       dataset: 'centers',
  //       size: 10,
  //       filters: JSON.stringify({ parent_flag: false }),
  //     }),
  //   );
  //   setFromForPi(fromForCenter + 1)
  // }

  const loadMoreRowsCenterListInNetwork = () => {
    const network_id = parse(location.search).id
    const from = fromForCenterInNetworkList + 1
    const size = 10
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
    const query1 = centerValueToSearch

    setFromForCenterInNetworkList(fromForCenterInNetworkList + 1);
    dispatch(getNetworkDetailsMoreAction(
      {
        network_id, from, size, filters, type, query1,
      },
    ))
  }

  const handleCenterCheck = (id) => {
    if (centerListToSend.includes(id)) {
      const listToSend = centerListToSend.filter((ele) => ele !== id);
      setCenterListToSend(listToSend);
    } else {
      setCenterListToSend((current) => [...current, id]);
    }
  }

  // const renderAutoSuggestOptions = () => {
  //   if (piAutoSuggest.data.data.length === 0) {
  //     return (
  //       <div className="no-data-found-msg">No Centers found</div>
  //     )
  //   }
  //   return piAutoSuggest.data.data.map((itm) => {
  //     return (
  //       <>
  //         <div
  //           className={`card ${itm.type}`}
  //         >
  //           <div className="card-header">
  //             <div className="card-title">{itm.name}</div>
  //           </div>
  //           <div className="content">
  //             {/* <div className="leftside">
  //               <div className="person-details">
  //                 <div className="contact_name">
  //                   <div className="title-child">Address:</div>
  //                   {itm.address
  //                     ? (
  //                       <div className="content-child">{`${itm.address.city ? itm.address.city : ''}${itm.address.city ? ', ' : ''}${itm.address.state ? itm.address.state : ''}${itm.address.state ? ', ' : ''}${itm.address.country ? itm.address.country : ''}${itm.address.country ? ', ' : ''}${itm.address.zip ? itm.address.zip : ''}`}</div>
  //                     )
  //                     : (
  //                       <div className="content-child no-data">No data available</div>
  //                     )}
  //                 </div>
  //                 <div className="emailofcontact">
  //                   <div className="title-child">Email</div>
  //                   {itm.email && itm.email.length
  //                     ? (
  //                       <div className="content-child">{itm.email.join(', ')}</div>
  //                     )
  //                     : (
  //                       <div className="content-child no-data">No data available</div>
  //                     )}
  //                 </div>
  //                 <div className="mobileOfcontact">
  //                   <div className="title-child">Phone</div>
  //                   {itm.phone_number && itm.phone_number.length
  //                     ? (
  //                       <div className="content-child">{itm.phone_number.join(', ')}</div>
  //                     )
  //                     : (
  //                       <div className="content-child no-data">No data available</div>
  //                     )}
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="right-side right-side-with-sep">
  //               <div className="tags-data">
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.tier}</div>
  //                     <div className="sizeOftag">{itm.tier}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.alliance}</div>
  //                     <div className="sizeOftag">{itm.alliance ? 'Yes' : 'No'}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.compliance}</div>
  //                     <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
  //                     <div className="sizeOftag">{itm.total_number_of_popis}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.interventional_studies}</div>
  //                     <div className="sizeOftag">{itm.interventional_studies}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.observational_studies}</div>
  //                     <div className="sizeOftag">{itm.observational_studies}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.parexel_studies}</div>
  //                     <div className="sizeOftag">{itm.parexel_studies}</div>
  //                   </div>
  //                 </Tag>
  //                 {/* <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.no_of_indications}</div>
  //                     <span className="sizeOftag">
  //                       {itm.indications && itm.indications.length
  //                         ? itm.indications.length
  //                         : 0}
  //                     </span>
  //                   </div>
  //                 </Tag> */}
  //             {/* <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.indications}</div>
  //                     <Tooltip
  //                       placement="bottom"
  //                       title={
  //                               itm.indications && itm.indications.length
  //                                 ? itm.indications.join('; ')
  //                                 : ''
  //                             }
  //                     >
  //                       <span className="sizeOftag">
  //                         {itm.indications && itm.indications.length
  //                           ? itm.indications.join('; ')
  //                           : ''}
  //                       </span>
  //                     </Tooltip>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">Sources: &nbsp;</div>
  //                     <Tooltip
  //                       placement="bottom"
  //                       title={
  //                               itm.sources && itm.sources.length
  //                                 ? itm.sources.join('; ')
  //                                 : ''
  //                             }
  //                     >
  //                       <span className="sizeOftag">
  //                         {itm.sources && itm.sources.length
  //                           ? itm.sources.join('; ')
  //                           : '-'}
  //                       </span>
  //                     </Tooltip>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">Center Type: &nbsp;</div>
  //                     <span className="sizeOftag">
  //                       {itm.center_type ? itm.center_type : '-'}
  //                     </span>
  //                   </div>
  //                 </Tag>
  //               </div>
  //               <div className="reliability-quality-rating">
  //                 <div className="reliability rating-sec">
  //                   <div className="rating-sec-title">Reliability</div>
  //                   {itm.reliability && itm.reliability !== ''
  //                     ? (
  //                       <StarRatings
  //                         rating={itm.reliability}
  //                         starRatedColor="#8A0050"
  //                         numberOfStars={3}
  //                         starDimension="16px"
  //                         starSpacing="1px"
  //                         starEmptyColor="#D099B9"
  //                       />
  //                     )
  //                     : (
  //                       <div className="content-child no-data">&nbsp;No data available</div>
  //                     )}
  //                 </div>
  //                 <div className="quality rating-sec">
  //                   <div className="rating-sec-title">Quality</div>
  //                   {itm.quality && itm.quality !== ''
  //                     ? (
  //                       <StarRatings
  //                         rating={itm.quality}
  //                         starRatedColor="#8A0050"
  //                         numberOfStars={3}
  //                         starDimension="16px"
  //                         starSpacing="1px"
  //                         starEmptyColor="#D099B9"
  //                       />
  //                     )
  //                     : (
  //                       <div className="content-child no-data">&nbsp;No data available</div>
  //                     )}
  //                 </div>
  //               </div>
  //             </div> */}

  //             <div className="leftside">
  //               <div className="person-details">
  //                 <div className="contact_name">
  //                   <div className="title-child">Address:</div>
  //                   {itm.address
  //                     ? (
  //                       <div className="content-child">{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
  //                         {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
  //                         {itm?.address?.city && `${itm?.address?.city}, `}
  //                         {itm?.address?.state && `${itm?.address?.state}, `}
  //                         {itm?.address?.country && `${itm?.address?.country}, `}
  //                         {itm?.address?.zip}
  //                       </div>
  //                     )
  //                     : (
  //                       <div className="content-child no-data">No data available</div>
  //                     )}
  //                 </div>
  //                 <div className="emailofcontact">
  //                   <div className="title-child">Email:</div>
  //                   {itm.email && itm.email.length
  //                     ? (
  //                       <>
  //                         <div className="content-child">
  //                           {itm.email.join(', ')}
  //                           <InputWithCopy text={itm} type="email" />
  //                         </div>
  //                       </>
  //                     )
  //                     : (
  //                       <div className="content-child no-data">No data available</div>
  //                     )}
  //                 </div>
  //                 <div className="mobileOfcontact">
  //                   <div className="title-child">Phone:</div>
  //                   {itm.phone_number && itm.phone_number.length
  //                     ? (
  //                       <div className="content-child">{itm.phone_number.join(', ')}</div>
  //                     )
  //                     : (
  //                       <div className="content-child no-data">No data available</div>
  //                     )}
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="right-side">
  //               <div style={{ display: 'flex' }}>
  //                 <div style={{ color: '#848484' }}> Parent Institution Name: </div>
  //                 {itm?.parent_institution && Object.keys(itm?.parent_institution).length ? (
  //                   itm?.parent_institution?.name

  //                 ) : (
  //                   <span className="value" style={{ fontSize: '14px' }}>&nbsp;No data available</span>
  //                 )}
  //               </div>
  //               <div className="tags-data">
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.tier}</div>
  //                     <div className="sizeOftag" role="presentation">{itm.tier}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.alliance}</div>
  //                     <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.compliance}</div>
  //                     <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
  //                     <div className="sizeOftag">{itm.total_number_of_popis}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.industry_studies}</div>
  //                     <div className="sizeOftag">{itm.industry_studies}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.interventional_studies}</div>
  //                     <div className="sizeOftag">{itm.interventional_studies}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.observational_studies}</div>
  //                     <div className="sizeOftag">{itm.observational_studies}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.parexel_studies}</div>
  //                     <div className="sizeOftag">{itm.parexel_studies}</div>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
  //                     <span className="sizeOftag">
  //                       {itm.enrollment_subjects}
  //                     </span>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.department}</div>
  //                     <span className="sizeOftag">
  //                       {itm.department ? itm.department : '-'}
  //                     </span>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">{tagTitle.indications}</div>
  //                     <Tooltip
  //                       placement="bottom"
  //                       title={
  //                           itm.indications && itm.indications.length
  //                             ? itm.indications.join('; ')
  //                             : '-'
  //                         }
  //                     >
  //                       <span className="sizeOftag">
  //                         {itm.indications && itm.indications.length
  //                           ? itm.indications.join('; ')
  //                           : '-'}
  //                       </span>
  //                     </Tooltip>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">Sources&nbsp;</div>
  //                     <Tooltip
  //                       placement="bottom"
  //                       title={
  //                           itm.sources && itm.sources.length
  //                             ? itm.sources.join(', ')
  //                             : '-'
  //                         }
  //                     >
  //                       <span className="sizeOftag">
  //                         {itm.sources && itm.sources.length
  //                           ? itm.sources.join(', ')
  //                           : '-'}
  //                       </span>
  //                     </Tooltip>
  //                   </div>
  //                 </Tag>
  //                 <Tag>
  //                   <div className="details-abt-tags">
  //                     <div className="nameOfTag">Center type&nbsp;</div>
  //                     <Tooltip
  //                       placement="bottom"
  //                       title={
  //                           itm.center_type
  //                             ? itm.center_type
  //                             : '-'
  //                         }
  //                     >
  //                       <span className="sizeOftag">
  //                         {itm.center_type
  //                           ? itm.center_type
  //                           : '-'}
  //                       </span>
  //                     </Tooltip>
  //                   </div>
  //                 </Tag>
  //                 {itm.pxl_center_sims_ids
  //                   ? (
  //                     <Tag>
  //                       <div className="details-abt-tags">
  //                         <div className="nameOfTag">SIMS IDs&nbsp;</div>
  //                         <Tooltip
  //                           placement="bottom"
  //                           title={
  //                               itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
  //                                 ? itm.pxl_center_sims_ids.join(', ')
  //                                 : '-'
  //                             }
  //                         >
  //                           <span className="sizeOftag">
  //                             {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length ? itm.pxl_center_sims_ids.join(', ') : '-'}
  //                           </span>
  //                         </Tooltip>
  //                       </div>
  //                     </Tag>
  //                   )
  //                   : null}
  //                 {itm.pxl_center_cit_ids
  //                   ? (
  //                     <Tag>
  //                       <div className="details-abt-tags">
  //                         <div className="nameOfTag">Citeline IDs&nbsp;</div>
  //                         <Tooltip
  //                           placement="bottom"
  //                           title={
  //                               itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
  //                                 ? itm.pxl_center_cit_ids.join(', ')
  //                                 : '-'
  //                             }
  //                         >
  //                           <span className="sizeOftag">
  //                             {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length ? itm.pxl_center_cit_ids.join(', ') : '-'}
  //                           </span>
  //                         </Tooltip>
  //                       </div>
  //                     </Tag>
  //                   )
  //                   : null}

  //                 {itm.landscape_id
  //                   ? (
  //                     <Tag>
  //                       <div className="details-abt-tags">
  //                         <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
  //                         <Tooltip
  //                           placement="bottom"
  //                           title={
  //                               itm.landscape_id ? itm.landscape_id
  //                                 : '-'
  //                             }
  //                         >
  //                           <span className="sizeOftag">
  //                             {itm.landscape_id ? itm.landscape_id : '-'}
  //                           </span>
  //                         </Tooltip>
  //                       </div>
  //                     </Tag>
  //                   )
  //                   : null}
  //               </div>
  //               <div className="reliability-quality-rating">
  //                 <div className="reliability rating-sec">
  //                   <div className="rating-sec-title">Reliability</div>
  //                   {itm.reliability_class && itm.reliability_class !== ''
  //                     ? (
  //                       <StarRatings
  //                         rating={getStars(itm.reliability_class)}
  //                         starRatedColor="#8A0050"
  //                         numberOfStars={3}
  //                         starDimension="16px"
  //                         starSpacing="1px"
  //                         starEmptyColor="#D099B9"
  //                       />
  //                     )
  //                     : (
  //                       <div className="content-child no-data">&nbsp;No data available</div>
  //                     )}
  //                 </div>
  //               </div>
  //             </div>

  //             <Checkbox onClick={() => handleInvestigatorCheck(itm.id)} />
  //           </div>
  //         </div>
  //       </>
  //     )
  //   })
  // }

  const tagTitle = {
    tier: 'Tier',
    compliance: 'Compliance',
    alliance: 'Alliance type',
    indications: 'Indications',
    total_number_of_popis: 'Total PIs',
    industry_studies: 'Industry studies',
    interventional_studies: 'Interventional studies',
    observational_studies: 'Observational studies',
    parexel_studies: 'Parexel studies',
    enrollment_subjects: 'Enrolled subjects',
    department: 'Department',
  };

  const searchCenterValue = () => {
    const from = 1
    const size = 10
    const network_id = parse(location.search).id
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false })
    const query1 = centerValueToSearch
    setFromForCenterInNetworkList(1)
    dispatch(getNetworkDetailsAction({
      type, network_id, query1, from, size, filters,
    }))
  }

  const handleAddCenterToNetwork = () => {
    const structuredObject = {
      innoplexus_id: innoplexusId,
      pi_id: centerListToSend,
    };

    dispatch(
      putPiToNetworkAction({
        params: {
          type,
        },
        body: [structuredObject],
      }),
    );
    setCenterListToSend([])
    dispatch(getPiAutoSuggestResetAction({}))
    setAddNewCenterToNetworkModal(false)
  }
  const handleDeleteCenter = () => {
    const pi_id = centerIdToDelete.id

    dispatch(deletePiFromNetworkAction({
      pi_id,
      innoplexus_id: innoplexusId,
      type,
    }))
    setCenterIdToDelete('')
    setDeleteModal(false)
  }

  const handleDeleteClick = (id) => {
    setCenterIdToDelete(id)
    setDeleteModal(true)
  }
  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1
    }
    if (value === 'Class 2') {
      return 2
    }
    if (value === 'Class 1') {
      return 3
    }
    return 0
  }
  const renderCenterList = () => {
    if (centerList.length === 0) {
      return <div className="no-data-found-msg">No Centers in this network</div>
    }
    return centerList.map((itm) => {
      return (
        <>
          <div
            className={`card ${itm.type}`}
          >
            <div className="card-header">
              <div className="card-title">

                <a
                  className="card-content-title pi-wrap"
                  title={itm.name}
                  target="_blank"
                  href={`/search/deepdives?${stringify({
                    query: itm.name,
                    id: itm.id,
                    type: 'SITE',
                    currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                  })}`}
                  rel="noreferrer noopener"
                >
                  {itm.name}
                </a>
              </div>
            </div>
            <div className="content">
              <div className="leftside">
                <div className="person-details">
                  <div className="contact_name">
                    <div className="title-child">Address:</div>
                    {itm.address
                      ? (
                        <div className="content-child">{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                          {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                          {itm?.address?.city && `${itm?.address?.city}, `}
                          {itm?.address?.state && `${itm?.address?.state}, `}
                          {itm?.address?.country && `${itm?.address?.country}, `}
                          {itm?.address?.zip}
                        </div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="emailofcontact">
                    <div className="title-child">Email:</div>
                    {itm.email && itm.email.length
                      ? (
                        <>
                          <div className="content-child">
                            {itm.email.join(', ')}
                            <InputWithCopy text={itm} type="email" />
                          </div>
                        </>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Phone:</div>
                    {itm.phone_number && itm.phone_number.length
                      ? (
                        <div className="content-child">{itm.phone_number.join(', ')}</div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div style={{ display: 'flex' }}>
                  <div style={{ color: '#848484' }}> Parent Institution Name: </div>
                  {itm?.parent_institution && Object.keys(itm?.parent_institution).length ? (
                    <a
                      target="_blank"
                      href={`/search/deepdives?${stringify({
                        ...parse(location.search),
                        id: itm?.parent_institution?.id,
                        currentTab: 'parent_centers',
                      })}`}
                      rel="noopener noreferrer"
                    >&nbsp;{itm?.parent_institution?.name}
                    </a>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>&nbsp;No data available</span>
                  )}
                </div>
                <div className="tags-data">
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.tier}</div>
                      <div className="sizeOftag" role="presentation">{itm.tier}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.alliance}</div>
                      <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.compliance}</div>
                      <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                      <div className="sizeOftag">{itm.total_number_of_popis}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.industry_studies}</div>
                      <div className="sizeOftag">{itm.industry_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                      <div className="sizeOftag">{itm.interventional_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.observational_studies}</div>
                      <div className="sizeOftag">{itm.observational_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                      <div className="sizeOftag">{itm.parexel_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                      <span className="sizeOftag">
                        {itm.enrollment_subjects}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.department}</div>
                      <span className="sizeOftag">
                        {itm.department ? itm.department : '-'}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.indications}</div>
                      <Tooltip
                        placement="bottom"
                        title={
                          itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'
                        }
                      >
                        <span className="sizeOftag">
                          {itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Sources&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                          itm.sources && itm.sources.length
                            ? itm.sources.join(', ')
                            : '-'
                        }
                      >
                        <span className="sizeOftag">
                          {itm.sources && itm.sources.length
                            ? itm.sources.join(', ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Center type&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                          itm.center_type
                            ? itm.center_type
                            : '-'
                        }
                      >
                        <span className="sizeOftag">
                          {itm.center_type
                            ? itm.center_type
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  {itm.pxl_center_sims_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">SIMS IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                ? itm.pxl_center_sims_ids.join(', ')
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length ? itm.pxl_center_sims_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                  {itm.pxl_center_cit_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Citeline IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                ? itm.pxl_center_cit_ids.join(', ')
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length ? itm.pxl_center_cit_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}

                  {itm.landscape_id
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.landscape_id ? itm.landscape_id
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.landscape_id ? itm.landscape_id : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                </div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Reliability</div>
                    {itm.reliability_class && itm.reliability_class !== ''
                      ? (
                        <StarRatings
                          rating={getStars(itm.reliability_class)}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                </div>
              </div>
              {
                !isViewOnlyAccess() && (
                  <Button className="del-icon-btn second-del" type="primary" onClick={() => handleDeleteClick(itm)} />
                )
              }
            </div>
          </div>
        </>
      )
    })
  }

  const handleAddNewCenterToNetworkModalClose = () => {
    dispatch(getPiAutoSuggestResetAction())
    setCenterListToSend([])
    setAddNewCenterToNetworkModal(false)
    setSwitchChange(false)
    setCenterList([])
    setSearchText('')
  }
  const handleAddCenterToNetworkClick = () => {
    if (networkType === 'Investigator Network') {
      notify("You are not allowed to add the centers for 'investigators network type'", 'error')
    } else {
      setCenterListToSend([])
      setAddNewCenterToNetworkModal(true)
    }
  }

  const onSwitchChange = (checked) => {
    setSwitchChange(checked);
  };

  const applyFiltersForCenter = (filtersObj) => {
    setAppliedFiltersForCenter({ ...appliedFiltersForCenter, ...filtersObj });
    setFromForCenter(1)
    dispatch(
      getDatasetListDataAction({
        query,
        dataset: 'centers',
        from: 1,
        size: 25,
        filters: JSON.stringify({ ...filtersObj, parent_flag: false }),
      }),
    );
  };

  const resetFiltersForCenter = () => {
    const temp = {
      alliance: undefined,
      quality_rating: undefined,
      tier: undefined,
    };
    setFromForCenter(1)
    setAppliedFiltersForCenter(temp);
    dispatch(
      getDatasetListDataAction({
        query,
        dataset: 'centers',
        from: 1,
        size: 25,
        filters: JSON.stringify({ ...temp, parent_flag: false }),
      }),
    );
  };

  const onSearch = (e) => {
    // setShowDocProfile(false)
    // setDisplayDropdown(false);
    setQuery(e);
    dispatch(
      getDatasetListDataAction({
        query: e,
        dataset: 'centers',
        from: 1,
        size: 25,
        filters: JSON.stringify({ parent_flag: false }),
      }),
    );
    setFromForCenter(1);
    // setShowDocProfile(true)
  };

  const onChangeInput = (e) => {
    setQuery(e.target.value);
    setFromForCenter(1);
    dispatch(getAutocompleteValuesCancelAction());
    if (e.target.value && e.target.value.trim().length <= 2) {
      // setDisplayDropdown(true);
      setSearchText(e.target.value);
      dispatch(getAutocompleteValuesResetAction());
    } else if (
      e.target.value
      && e.target.value.trim().length > 2
      && !emojiRegex.test(e.target.value)
    ) {
      // setDisplayDropdown(true);
      setSearchText(e.target.value);
      // dispatch(
      //   getAutocompleteValuesAction({
      //     query: e.target.value.trim(),
      //     dataset: 'investigators',
      //     filters: JSON.stringify({ parent_flag: false }),
      //   })
      // );
    } else {
      // setDisplayDropdown(false);
      setSearchText('');
    }
  };

  const loadMoreRowsList = () => {
    //  setShowDocProfile(true)
    setFromForCenter(fromForCenter + 1);
    const pageNo = fromForCenter + 1
    dispatch(
      getMoreDatasetListDataAction({
        query,
        dataset: 'centers',
        page: pageNo,
        size: 25,
        filters: JSON.stringify({ ...appliedFiltersForCenter, parent_flag: false }),
      }),
    );
  };

  const renderCenterListForModal = () => {
    if (CenterList.length === 0) {
      return <div className="no-data-found-msg">No Centers in this network</div>
    }
    return CenterList.map((itm) => {
      return (
        <>
          <div
            className={`card ${itm.type}`}
          >
            <div className="card-header">
              <div className="card-title">

                <a
                  className="card-content-title pi-wrap"
                  title={itm.name}
                  target="_blank"
                  href={`/search/deepdives?${stringify({
                    query: itm.name,
                    id: itm.id,
                    type: 'SITE',
                    currentTab: 'centers',
                  })}`}
                  rel="noreferrer noopener"
                >
                  {itm.name}
                </a>
              </div>
            </div>
            <div className="content">
              <div className="leftside" style={{ minWidth: '15%', maxWidth: '15%' }}>
                <div className="person-details">
                  <div className="contact_name">
                    <div className="title-child">Address:</div>
                    {itm.address
                      ? (
                        <div className="content-child">{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                          {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                          {itm?.address?.city && `${itm?.address?.city}, `}
                          {itm?.address?.state && `${itm?.address?.state}, `}
                          {itm?.address?.country && `${itm?.address?.country}, `}
                          {itm?.address?.zip}
                        </div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="emailofcontact">
                    <div className="title-child">Email:</div>
                    {itm.email && itm.email.length
                      ? (
                        <>
                          <div className="content-child">
                            {itm.email.join(', ')}
                            <InputWithCopy text={itm} type="email" />
                          </div>
                        </>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Phone:</div>
                    {itm.phone_number && itm.phone_number.length
                      ? (
                        <div className="content-child">{itm.phone_number.join(', ')}</div>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div style={{ display: 'flex' }}>
                  <div style={{ color: '#848484' }}> Parent Institution Name: </div>
                  {itm?.parent_institution && Object.keys(itm?.parent_institution).length ? (
                    <a
                      target="_blank"
                      href={`/search/deepdives?${stringify({
                        ...parse(location.search),
                        id: itm?.parent_institution?.id,
                        currentTab: 'parent_centers',
                      })}`}
                      rel="noopener noreferrer"
                    >&nbsp;{itm?.parent_institution?.name}
                    </a>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>&nbsp;No data available</span>
                  )}
                </div>
                <div className="tags-data">
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.tier}</div>
                      <div className="sizeOftag" role="presentation">{itm.tier}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.alliance}</div>
                      <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.compliance}</div>
                      <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                      <div className="sizeOftag">{itm.total_number_of_popis}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.industry_studies}</div>
                      <div className="sizeOftag">{itm.industry_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                      <div className="sizeOftag">{itm.interventional_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.observational_studies}</div>
                      <div className="sizeOftag">{itm.observational_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                      <div className="sizeOftag">{itm.parexel_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                      <span className="sizeOftag">
                        {itm.enrollment_subjects}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.department}</div>
                      <span className="sizeOftag">
                        {itm.department ? itm.department : '-'}
                      </span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.indications}</div>
                      <Tooltip
                        placement="bottom"
                        title={
                          itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'
                        }
                      >
                        <span className="sizeOftag">
                          {itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Sources&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                          itm.sources && itm.sources.length
                            ? itm.sources.join(', ')
                            : '-'
                        }
                      >
                        <span className="sizeOftag">
                          {itm.sources && itm.sources.length
                            ? itm.sources.join(', ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Center type&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={
                          itm.center_type
                            ? itm.center_type
                            : '-'
                        }
                      >
                        <span className="sizeOftag">
                          {itm.center_type
                            ? itm.center_type
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  {itm.pxl_center_sims_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">SIMS IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                ? itm.pxl_center_sims_ids.join(', ')
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length ? itm.pxl_center_sims_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                  {itm.pxl_center_cit_ids
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Citeline IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                ? itm.pxl_center_cit_ids.join(', ')
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length ? itm.pxl_center_cit_ids.join(', ') : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}

                  {itm.landscape_id
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.landscape_id ? itm.landscape_id
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.landscape_id ? itm.landscape_id : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    )
                    : null}
                </div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Reliability</div>
                    {itm.reliability_class && itm.reliability_class !== ''
                      ? (
                        <StarRatings
                          rating={getStars(itm.reliability_class)}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                </div>
              </div>

              <Checkbox onClick={() => handleCenterCheck(itm.id)} />
            </div>
          </div>
        </>
      )
    })
  }

  return (
    <>

      {
        deleteModal && (
          <Modal title="Delete Center from Network" visible={deleteModal} onOk={handleDeleteCenter} onCancel={() => setDeleteModal(false)}>
            Do you really want to delete center from this network ?
          </Modal>
        )
      }
      <div className="whole-deep-dive-page whole-deep-dive-page-inpage">
        <div className="middle-part" style={{ minWidth: '100%' }}>
          <div className="upper-divof-search-Tabs">
            <div className="outer_large_div">
              <div className="network-inner-content-card">
                {networkData.loading ? <Loader loading={networkData.loading} error={networkData.error} />
                  : (
                    <>
                      <div className="header-network-content">
                        {
                          !isViewOnlyAccess() && (
                            <div className="header-network-content-search">
                              <Search
                                placeholder="Search Center"
                                allowClear
                                value={centerValueToSearch}
                                onChange={(e) => setCenterValueToSearch(e.target.value)}
                                onSearch={searchCenterValue}
                                onPressEnter={searchCenterValue}
                                enterButton
                              />
                            </div>

                          )
                        }
                        <Loader loading={networkData.loading} error={networkData.error}>

                          {!networkData.loading && networkData.flag && (
                            <>
                              {centerList?.length ? (
                                <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
                                  <div className="show-doc-data">
                                    Showing<span className="show-count">{centerList?.length}</span> out of
                                    <span className="show-count-number">{networkData?.data?.data?.totalPIs}</span> Centers
                                  </div>
                                </div>
                              ) : undefined}
                              <LazyLoadDiv
                                className="card-list scrollbar"
                                id="pi-list-in-Network"
                                total={networkData.data.data.totalPIs}
                                currentTotal={(networkData.data.data.pi_summary || []).length}
                                loadMoreRows={() => loadMoreRowsCenterListInNetwork()}
                                height="calc(100vh - 435px)"
                              >
                                {renderCenterList()}
                              </LazyLoadDiv>
                            </>
                          )}
                        </Loader>
                      </div>
                    </>
                  )}
              </div>
              {
                !isViewOnlyAccess() && (
                  <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis">
                    <div className="plus-add-btn center-tab-network-deepdive">
                      <button onClick={() => handleAddCenterToNetworkClick()} type="button" className="plus-btn">
                        <span className="title" style={{ display: 'none' }}>
                          Add Centers to Network&nbsp;
                        </span>
                        <span className="title-plus"> +</span>
                      </button>
                    </div>
                  </div>
                )
              }
              {addCenterToNetworkModal && (

                <Modal
                  title="Add Centers to Network"
                  visible={addCenterToNetworkModal}
                  onOk={handleAddCenterToNetwork}
                  onCancel={handleAddNewCenterToNetworkModalClose}
                  width="1000"
                  className="add-investigator-network-modal"
                  okText="Add"
                  maskClosable={false}
                  okButtonProps={{ disabled: centerListToSend.length === 0 }}
                >

                  <div style={{ display: 'flex' }}>
                    <div className="searchbar-data">
                      <div className="search-result" style={{ maxHeight: 'calc(100vh - 187px)' }}>
                        <Tooltip title="Filters">
                          <Switch
                            onChange={onSwitchChange}
                            className="switch-sidebar-content"
                            style={{ top: '65px', left: '30px' }}
                          />
                        </Tooltip>

                        {switchChange ? (
                          <div className="filter-wrap network-filter">

                            <Collapse
                              bordered={false}
                              defaultActiveKey={['1']}
                              expandIcon={({ isActive }) => (
                                <CaretRightOutlined rotate={isActive ? 90 : 0} />
                              )}
                              className="site-collapse-custom-collapse"
                            >
                              <Panel
                                header="Filters"
                                key="1"
                                className="site-collapse-custom-panel sidebar-menu center-map-sidebar sidebar-filter-i-c"
                              >
                                <div className="filter-content">

                                  <SearchResultFilters
                                    dataset="centers"
                                    appliedFilters={appliedFiltersForCenter}
                                    onApplyFilters={applyFiltersForCenter}
                                    onReset={resetFiltersForCenter}
                                  />

                                </div>

                              </Panel>
                            </Collapse>
                          </div>
                        ) : null}

                      </div>
                    </div>
                    <div className="search-bar search-wrap center-list">
                      <div className="search-bar-wrap">

                        {/* <Tooltip title="Filters">
              <Switch
                onChange={onSwitchChange}
                className="switch-sidebar-content"
                style={{ top: '65px', left: '30px' }}
              />
            </Tooltip> */}
                        <div className="search-box">
                          <Search
                            placeholder="Search center"
                            //   placeholder={(flagName === 'runAnalysis' || flagName === 'savedAnalysis') ? 'Search by investigator name' : fieldToSearch === 'all' ? 'Search for Investigator, Center, Project, Network and Clinical Trial' : `Search for ${(fieldPlaceholderMapping[fieldToSearch])}`}
                            allowClear
                            value={searchText}
                            loading={autoCompleteData.loading}
                            enterButton={<Button disabled={!(searchText.length > 2)}>Search center</Button>}
                            size="large"
                            maxLength="256"
                            onSearch={onSearch}
                            onChange={onChangeInput}
                          />
                        </div>
                      </div>
                      <div className="search-dropdown-result-content">
                        {/* {displayDropdown ? (
              <OutsideClick
                ignoreClickWithinClass="search-bar"
                onClickOutside={() => {
                  setDisplayDropdown(false);
                }}
              >
                <div className="search-dropdown-result">
                  <div className="search-dropdown-result-list">
                    <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                      {renderAutosuggestOptions()}
                    </Loader>
                  </div>
                </div>
              </OutsideClick>
            ) : null} */}
                      </div>
                      <Loader
                        loading={datasetListData.loading}
                        error={datasetListData.error}
                        style={{ width: '100%' }}
                      >
                        {CenterList.length !== 0 && (
                          <div className="inner-row-search-data">
                            {/* {showDocProfile && ( */}
                            <LazyLoadDiv
                              className="card-list scrollbar"
                              id="pi-list-in-search"
                              total={datasetListData.data.total}
                              currentTotal={(datasetListData.data.data || []).length}
                              loadMoreRows={() => loadMoreRowsList()}
                              height="calc(100vh - 400px)"
                            >
                              {renderCenterListForModal()}
                            </LazyLoadDiv>
                            {/* )} */}
                          </div>
                        )}
                      </Loader>
                    </div>
                  </div>
                  {/* <div className="add-investigator-to-network">
                  <Input placeholder="Search Centers here" onChange={(e) => onInvestigatorChangeInput(e.target.value)} />
                  <div className="Card">
                    <Loader loading={piAutoSuggest.loading} error={piAutoSuggest.error}>
                      {!piAutoSuggest.loading && piAutoSuggest.flag && (
                      <LazyLoadDiv
                        className="card-list scrollbar"
                        id="pi-list-in-search"
                        total={piAutoSuggest.data.total}
                        currentTotal={(piAutoSuggest.data.data || []).length}
                        loadMoreRows={() => loadMoreRowsPiList()}
                        height="calc(100vh - 275px)"
                      >{renderAutoSuggestOptions()}
                      </LazyLoadDiv>
                      )}
                    </Loader>
                  </div>

                </div> */}
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NetworkCenterList;
